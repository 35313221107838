.messageBox {
  border-radius: 0.562rem;
  align-items: end;
  margin-top: 0.75rem;
  padding: 1rem;
  max-width: 20rem;
  position: relative;
}

.sentMessage::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 22px solid transparent;
  border-left-color: #472980;
  border-right: 0;
  margin-top: -20px;
  margin-right: -12px;
}

.receivedMessage::after {
  content: '';
  position: absolute;
  left: -0.6rem;
  top: 50%;
  width: 0;
  height: 0;
  border: 22px solid transparent;
  border-right-color: #ffffff;
  border-left: 0;
  margin-top: -20px;
  margin-right: -12px;
}

.messageBoxText {
  font-size: 0.875rem;
  font-weight: 400;
  display: block;
}

.messageBoxTime {
  font-size: 0.7rem;
  font-weight: 500;
  float: right;
  margin-top: 10px;
  padding-left: 5px;
  margin-bottom: -5px;
  font-style: italic;
}
